
import {computed, defineComponent, inject} from "vue";
import {useStore} from "@/store";
import Api from "@/services/api";
import {$vfm} from "vue-final-modal";

export default defineComponent({
  emits: ['success'],
  data(){
    return {
      formUrl:null
    }
  },
  methods:{
    test(){
      alert(1);
    }
  },
  mounted() {

    this.api.addCard().then((resp: any)=>{

      let data = resp.data;

      if(data.formUrl){
        this.formUrl = data.formUrl
      }
    })

    window.addEventListener('message', event => {
      if(event.data == 'success'){
        this.$emit('success')
      }
    });
  },
  setup() {
    const api = inject<Api>("api", () => new Api(), true);
    return {
      api
    };
  }
});
