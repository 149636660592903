
import {
  computed,
  defineComponent,
  PropType,
  ref,
  Ref,
  reactive,
  inject,
} from "vue";
import { IBasket } from "@/models/basket";
import { useStore } from "@/store";
import { $vfm } from "vue-final-modal";
import TheTest from "@/components/TheTest.vue";
import PickUpLocation from "@/components/PickUpLocation.vue";
import ADeliveryDate from "@/components/controls/ADeliveryDate.vue";

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue';

// Import Swiper styles
import 'swiper/css';

interface IOrder {
  delivery: Array<string>;
  oneDay: boolean;
  name: string;
  email: string;
  phone: string;
  entrance: string;
  floor: string;
  flat: string;
  comment: string;
  card: object|null;
}

import { useVuelidate } from "@vuelidate/core";
import { required, email, helpers } from "@vuelidate/validators";
import ConfirmOrder from "@/components/ConfirmOrder.vue";
import Api from "@/services/api";
import ThePersonalData from "./ThePersonalData.vue";
import PersonalData from "./PersonalData.vue";
import AddPayCard from "@/components/AddPayCard.vue";

export default defineComponent({
  components: {
    ADeliveryDate,
    Swiper,
    SwiperSlide,
  },
  props: {
    source: {
      type: Object as PropType<IBasket>,
      required: true,
    },
  },
  data() {
    return {
      shipments: [],
      cards:[],
      total:[],
      isShowBtnOneDay: false,
      loading: false,
      paySms: {
        bankCode: "other-bank",
        bankName: "Другой банк",
        bindingId: "sms",
        cardType:"",
        displayFullLabel: "",
        displayLabel: "Оплата по ссылке",
        expiryDate: "12/24"
      }
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    showPersonalData() {
      $vfm.show({
        component: ThePersonalData,
        bind: {
          title: "Персональные данные",
        },
        slots: {
          content: {
            component: PersonalData,
          },
        },
      });
    },
    showModalChangeDelivery() {
      $vfm.show({
        component: TheTest,
        bind: { title: "Выберите способ получения" },
        slots: {
          content: {
            component: PickUpLocation,
            on: {
              success() {
                if ($vfm.dynamicModals[$vfm.dynamicModals.length - 1]) {
                  $vfm.dynamicModals[$vfm.dynamicModals.length - 1].value =
                    false;
                }
              },
            },
          },
        },
      });
    },
    init() {
      this.store.dispatch("checkout", { action: "init" }).then((data) => {
        this.shipments = data.shipments;
        if (this.shipments.length > 0) {
          this.shipments.forEach((item: any, index: number) => {
            let d = item.dates[0] as any;
            this.model.delivery.push(d.date);
          });
          // this.model.delivery = delivery;
          // this.delivery = delivery;
        }

        this.total = data.total;

        if(data.paySystem){
          data.paySystem.cards.push(this.paySms)
          this.cards = data.paySystem.cards;
        }

        if(this.shipments.length>1){
          this.isShowBtnOneDay = true;
        }
      });

      if(this.user){
        this.model.name = this.user.name;
        this.model.phone = this.user.phone;
        this.model.email = this.user.email;
      }
    },
    sendRequest() {
      this.store
        .dispatch("checkout", { ...{ action: "refresh" }, ...this.model })
        .then((data) => {
          this.model.delivery = [];
          this.shipments = data.shipments;
          if (this.shipments.length > 0) {
            this.shipments.forEach((item: any, index: number) => {
              let d = item.dates[0] as any;
              this.model.delivery.push(d.date);
            });
            // this.model.delivery = delivery;
            // this.delivery = delivery;
          }
        });
    },
    submit() {
      this.v$.$validate().then((result) => {

        if(this.v$.$errors.length==1 &&
          this.v$.$errors[0].$property == 'card' &&
          this.cards.length == 0
        ){
          this.addPayCard(()=>{
            if(this.cards[0]){
              this.model.card = this.cards
              this.submit();
            }
          });
        }

        if (result) {

          this.loading = true;
          this.api
            .checkout({
              ...{ action: "save" },
              ...this.model,
              ...{ deliveryType: this.deliveryType },
              ...{ deliveryPoint: this.deliveryPoint },
              ...{ pickupPoint: this.pickupPoint },
            })
            .then((resp) => {
              let data = resp.data;

              if (data.orders && data.orders.length>0) {
                if ($vfm.dynamicModals[$vfm.dynamicModals.length - 1]) {
                  $vfm.dynamicModals[$vfm.dynamicModals.length - 1].value =
                    false;
                }
                $vfm.show({
                  component: TheTest,
                  slots: {
                    content: {
                      component: ConfirmOrder,
                      bind: {
                        orderId: data.orders.map(r=>r.orderId).join(', '),
                      },
                    },
                  },
                });

                this.store.commit('setBasket',{
                  items: [],
                  total: {
                    basePrice: 0,
                    discount: 0,
                    discountPercent: 0,
                    price: 0,
                  },
                });
              }
            }).finally(()=>{
            this.loading = false;
          });
        }
      });
    },
    addPayCard(callback){

      let updateCardList = () => {
        return this.api.getCardList().then((resp)=>{
          let data = resp.data;
          if(data.cardList){
            this.cards = data.cardList;
          }
        });
      }

      $vfm.show({
        component: TheTest,
        bind: {
          title: "Добавление карты",
        },
        slots: {
          content: {
            component: AddPayCard,
            on: {
              success() {
                $vfm.dynamicModals[$vfm.dynamicModals.length - 1].value = false;
                updateCardList().then(()=>{
                  if(callback)
                    callback();
                });
              }
            }
          },
        },
      });
    },
    changeCard(item){
      this.model.card = item;
    },
    priceFormat(value: number) {
      return Intl.NumberFormat().format(value) + " ₽";
    },
  },
  watch: {},
  validations() {
    return {
      model: {
        name: {
          required: helpers.withMessage("Обязательное поле", required),
        },
        email: {
          required: helpers.withMessage("Обязательное поле", required),
          email: helpers.withMessage("E-mail некорректный", email),
        },
        phone: {
          required: helpers.withMessage("Обязательное поле", required),
        },
        entrance: {},
        floor: {},
        flat: {},
        comment: {},
        card:{
          required: helpers.withMessage("Выберите карту", required),
        }
      },
    };
  },
  setup() {
    const store = useStore();

    let model = ref<IOrder>({
      delivery: [],
      oneDay: false,
      name: "",
      email: "",
      phone: "",
      entrance: "",
      floor: "",
      flat: "",
      comment: "",
      card: null
    });

    const api = inject<Api>("api", () => new Api(), true);

    return {
      api,
      model,
      store,
      deliveryType: computed(() => store.state.deliveryType),
      deliveryPoint: computed(() => store.state.deliveryPoint),
      pickupPoint: computed(() => store.state.pickupPoint),
      user: computed(() => store.state.user),
      v$: useVuelidate(),
    };
  },
});
