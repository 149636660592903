
import { defineComponent, inject } from "vue";
import Api from "@/services/api";
import moment from "moment";
import { useStore } from "@/store";

export default defineComponent({
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      basket: [] as any,
      price: 0,
      shipments: [],
    };
  },
  mounted() {
    this.api.order(this.id).then((resp) => {
      let data = resp.data;

      this.basket = data.basket;
      this.price = data.price;
      this.shipments = data.shipments;
    });
  },
  methods: {
    repeatOrder() {
      this.loading = true;
      this.store.dispatch("deleteAllItemsBasket").finally(() => {
        this.store
          .dispatch("getBasket")
          .then(() => {
            for (let i = 0; i < this.basket.items.length; i++) {
              this.store
                .dispatch("addBasket", {
                  productId: this.basket.items[i].productId,
                  quantity: this.basket.items[i].quantity,
                })
                .then();
            }
          })
          .finally(() => {
            this.loading = false;
          });
      });
    },

    moment(date) {
      moment.locale("ru");
      return moment(date).format("DD MMMM");
    },
  },
  setup() {
    const store = useStore();
    const api = inject<Api>("api", () => new Api(), true);
    return {
      store,
      api,
    };
  },
});
