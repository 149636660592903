
import { defineComponent, inject } from "vue";
import Api from "@/services/api";
import TheAccount from "@/components/TheAccount.vue";
import AccountOrderDetail from "@/components/AccountOrderDetail.vue";
import { $vfm } from "vue-final-modal";

export default defineComponent({
  data() {
    return {
      loading: true,
      items: [],
      errors: [],
    };
  },
  methods: {
    formatDate(date) {
      let dd = date.getDate();
      if (dd < 10) dd = "0" + dd;

      let mm = date.getMonth() + 1;
      if (mm < 10) mm = "0" + mm;

      let yy = date.getFullYear();
      // if (yy < 10) yy = 0 + yy;
      return dd + "." + mm + "." + yy;
    },
    orderDetail(item) {
      $vfm.show({
        component: TheAccount,
        bind: {
          title: `Заказ №${item.id}`,
          date: this.formatDate(new Date(item.createdDate)),
          status: item.status.name,
          back: true,
        },
        slots: {
          content: {
            component: AccountOrderDetail,
            bind: {
              id: item.id
            },
          },
        },
      });
    },
  },
  mounted() {
    this.api
      .orders()
      .then((data) => {
        this.items = data.data.items;
        this.loading = false;
      })
      .catch((err) => {
        let data = err.response.data;
        this.errors = data.errors;
      });
  },
  setup() {
    const api = inject<Api>("api", () => new Api(), true);
    return {
      api,
    };
  },
});
