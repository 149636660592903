import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "a-delivery-date" }
const _hoisted_2 = { class: "a-delivery-date__top" }
const _hoisted_3 = { class: "a-delivery-date__top-date" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { class: "a-delivery-date__list" }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = { class: "a-delivery-date__list-item-day-month" }
const _hoisted_8 = { class: "a-delivery-date__list-item-day-week" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_ctx.value)
          ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.moment(_ctx.value)), 1))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", {
        class: "a-delivery-date__top-count",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showDetail()))
      }, _toDisplayString(_ctx.source.basket.length) + " товар" + _toDisplayString(_ctx.declOfNum(_ctx.source.basket.length,['','а','ов'])), 1)
    ]),
    _createElementVNode("div", _hoisted_5, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.source.dates, (date, idx) => {
        return (_openBlock(), _createElementBlock("button", {
          key: idx,
          class: _normalizeClass(["a-delivery-date__list-item", {'a-delivery-date__list-item__active': _ctx.value == date.date}]),
          onClick: ($event: any) => (_ctx.change(date.date))
        }, [
          _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.getDayMonth(date.formattedDate)), 1),
          _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.getDayWeek(date.formattedDate)), 1)
        ], 10, _hoisted_6))
      }), 128))
    ])
  ]))
}