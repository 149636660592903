import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  style: {"height":"100%"}
}
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.formUrl)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("iframe", {
          src: _ctx.formUrl,
          width: "100%",
          height: "100%",
          frameBorder: "0"
        }, "\n    Ваш браузер не поддерживает плавающие фреймы!\n  ", 8, _hoisted_2)
      ]))
    : _createCommentVNode("", true)
}