
import {defineComponent, PropType} from "vue";

export default defineComponent({
  props: {
    modelValue:{},
    source: {
      type: Function,
      required: true
    },
    delay:{
      type: Number,
      default: 600
    },
    label:{
      type: String,
      default: 'name'
    }
  },
  emits: ['update:modelValue','change'],
  data() {
    return {
      value:'',
      tm:0,
      items:[],
      show:false
    };
  },
  computed:{},
  methods: {
    input(event:any){
      if (this.tm)
        clearTimeout(this.tm);
      this.tm = setTimeout(() => {
        if(event.target.value.length==0){
          this.items = []
        }
        else{
          this.source(event.target.value).then((data:any)=>{
            this.items = data;
          });
        }
      }, this.delay);
    },
    change(item:any){
      this.value = item[this.label];
      this.$emit('update:modelValue', item);
      this.$emit('change', item);
    }
  },
  mounted() {
    if(this.modelValue && (this.modelValue as any)[this.label])
      this.value = (this.modelValue as any)[this.label];
  },
  watch:{
    modelValue(val: any){
      if(val && val[this.label]){
        this.value = val[this.label];
      }
      else
        this.value='';
    }
  }
});
