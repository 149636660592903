
import { defineComponent, inject } from "vue";
import Api from "@/services/api";

let noPhoto = require("@/assets/img/no-photo.png");

export default defineComponent({
  props: {
    source: {
      type: Object,
      required: true
    }
  },
  computed:{
    noPhoto(){
      return noPhoto;
    }
  },
  setup() {
    const api = inject<Api>("api", () => new Api(), true);
    return {
      api,
    };
  },
});
