import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "account-order" }
const _hoisted_2 = { class: "account-order__container" }
const _hoisted_3 = {
  key: 0,
  class: "loader"
}
const _hoisted_4 = {
  key: 1,
  class: "account-order__list"
}
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "account-order__item-left" }
const _hoisted_7 = { class: "account-order__item-title" }
const _hoisted_8 = { class: "account-order__item-date" }
const _hoisted_9 = { class: "account-order__item-right" }
const _hoisted_10 = { class: "account-order__item-status account-order__item-status--white" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.loading)
        ? (_openBlock(), _createElementBlock("span", _hoisted_3))
        : _createCommentVNode("", true),
      (!_ctx.loading)
        ? (_openBlock(), _createElementBlock("ul", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
              return (_openBlock(), _createElementBlock("li", {
                key: item.id,
                class: "account-order__item",
                onClick: ($event: any) => (_ctx.orderDetail(item))
              }, [
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("div", _hoisted_7, "№" + _toDisplayString(item.id), 1),
                  _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.formatDate(new Date(item.createdDate))), 1)
                ]),
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("div", _hoisted_10, _toDisplayString(item.status.name), 1)
                ])
              ], 8, _hoisted_5))
            }), 128))
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}