
import { defineComponent } from "vue";

export default defineComponent({
  data() {
    return {
      payDrawer: false,
      radioValue: 1,
      backDrawer: false,
    };
  },
  methods: {
    openPay() {
      this.payDrawer = true;
      this.backDrawer = false;
    },
    radioChange(event: any) {
      this.radioValue = event.value;
    },
    radioChangeCard(event: any) {
      // console.log(event.value);
    },
    backDrawerPay() {
      this.backDrawer = true;
      this.payDrawer = false;
      this.$emit("backDrawerPay", true);
    },
    closeDrawerPay() {
      this.payDrawer = false;
    },
    openAccepted() {
      this.payDrawer = false;
      this.$emit("openAccepted", true);
    },
  },
  watch: {
    payDrawer(event) {
      if (event == true) {
        document.querySelector("html")?.classList.add("html--active");
      } else if (this.backDrawer == true && event == false) {
        document.querySelector("html")?.classList.add("html--active");
      } else {
        document.querySelector("html")?.classList.remove("html--active");
      }
    },
  },
});
