
import { defineComponent } from "vue";

export default defineComponent({
  data() {
    return {
      accepted: false,
    };
  },
  methods: {
    openAccepted() {
      this.accepted = true;
    },
    openHistory(){
        this.accepted = false;
        this.$emit("openHistory", true);
    }
  },
});
