
import { defineComponent, PropType } from "vue";
import { useStore } from "@/store";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Pagination, Navigation } from "swiper";
import { IRecipe } from "@/models/recipe";
export default defineComponent({
  props: {
    item: {
      type: Object as PropType<IRecipe>,
      required: true,
    },
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      detailInfo: null,
    };
  },
  methods: {
    close() {
      this.store.commit("closeDialog", "recipe");
    },
  },
  mounted() {
    this.store.dispatch("getDetailRecipe", this.item.id).then((data) => {
      this.detailInfo = data.item;
    });
  },
  setup() {
    const store = useStore();
    return {
      modules: [Pagination, Navigation],
      store,
    };
  },
});
