
import {defineComponent, PropType} from "vue";
import moment from "moment";
import {$vfm} from "vue-final-modal";
import TheTest from "@/components/TheTest.vue";
import ConfirmOrder from "@/components/ConfirmOrder.vue";
import TheShipment from "@/components/TheShipment.vue";

export default defineComponent({
  props: {
    modelValue:{
      type: String,
      default: ''
    },
    source: {
      type: Object,
      required: true
    }
  },
  emits: ['update:modelValue','change'],
  data() {
    return {
      value:''
    };
  },
  mounted() {
    this.value = this.modelValue;
  },
  methods: {
    getDayWeek(date: string){
      return date.split(' ')[0];
    },
    getDayMonth(date: string){
      return date.split(' ')[1];
    },
    change(val:string){
      this.value = val;
      this.$emit('change', this.value);
      this.$emit('update:modelValue', this.value);
    },
    priceFormat(value: number) {
      return Intl.NumberFormat().format(value).replace(",", " ") + " ₽";
    },
    moment(date){
      moment.locale('ru');
      return moment(date).format('DD MMMM');
    },
    showDetail(){
      console.log(this.source);
      $vfm.show({
        component: TheTest,
        slots: {
          content: {
            component: TheShipment,
            bind: {
              source: this.source
            },
          },
        },
      });
    },
    declOfNum(number, words) {
      return words[(number % 100 > 4 && number % 100 < 20) ? 2 : [2, 0, 1, 1, 1, 2][(number % 10 < 5) ? Math.abs(number) % 10 : 5]];
    }
  },
  watch:{
    modelValue(val: any){
      this.value = val;
    }
  }
});
