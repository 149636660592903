
import { computed, defineComponent } from "vue";
import { YandexMap, YandexMarker, YandexClusterer } from "vue-yandex-maps";
import AAutocomplete from "@/components/controls/AAutocomplete.vue";
import { useStore } from "@/store";
import { IDeliveryPoint } from "@/models/deliveryPoint";

const MAP_MARKER_DEFAULT = require("@/assets/img/map_baloon.svg");
const MAP_MARKER_ACTIVE = require("@/assets/img/pin.svg");

export default defineComponent({
  props: {
    params: {
      type: Object,
    },
  },
  components: {
    YandexMap,
    YandexMarker,
    YandexClusterer,
    AAutocomplete,
  },
  emits: ["success", "test"],
  data() {
    return {
      isInit: false,
      storesList: [],
      selectedPickup: null,
      currentDeliveryType: "delivery",
      settings: {
        apiKey: "",
        lang: "ru_RU",
        coordorder: "latlong",
        debug: false,
        version: "2.1",
        onload: "onLoadPickupMap",
      },
      centerCoordinate: [45.03547, 38.975313],
      mC: [45.03547, 38.975313],
      tmMoveEnd: 0,
      tm: 0,
      location: null,
      mouseClick: false,
      stop: false,
      disableBtn: false,
    };
  },
  methods: {
    actionbegin(e: any) {
      if (this.tmMoveEnd) clearTimeout(this.tmMoveEnd);

      if (!this.stop) {
        this.disableBtn = true;
      }
    },
    actionend(e: any) {
      if (this.tmMoveEnd) clearTimeout(this.tmMoveEnd);

      this.tmMoveEnd = setTimeout(() => {
        this.changeCoordinate(e.get("map").getCenter());
      }, 500);
    },
    changeCoordinate(coords: any, ignore = false) {
      this.mC = coords; // Удалить
      console.log([this.stop, coords, this.centerCoordinate]);
      if (
        !this.stop &&
        ((coords[0] != this.centerCoordinate[0] &&
          coords[1] != this.centerCoordinate[1]) ||
          ignore)
      ) {
        this.location = null;
        this.store.dispatch("getLocationByCoordinate", coords).then((data) => {
          this.disableBtn = false;

          if (data.coordinate) {
            this.location = data;
            if (data.coordinate)
              this.centerCoordinate = [
                parseFloat(data.coordinate.lat),
                parseFloat(data.coordinate.lon),
              ];
          } else {
            alert("По указанному адресу доставка не осуществляется!");
          }

          this.stop = true;
        });
      } else {
        this.disableBtn = false;
      }
      this.stop = false;
    },
    getSuggestionsAddress(q: string) {
      return this.store.dispatch("getSuggestionsAddress", q);
    },
    changeLocation(item: any) {
      // this.centerCoordinate = [parseFloat(item.coordinate.lat), parseFloat(item.coordinate.lon)];

      this.stop = false;
      this.changeCoordinate(
        [parseFloat(item.coordinate.lat), parseFloat(item.coordinate.lon)],
        true
      );
    },
    mapDown() {
      this.mouseClick = true;
    },
    mapUp() {
      this.mouseClick = false;
    },
    submitDelivery() {
      let location = this.location ? (this.location as IDeliveryPoint) : null;

      let data = {
        type: "delivery",
        deliveryPoint: {
          address: location?.address,
          house: location?.house,
          coordinate: location?.coordinate,
        },
        deliverySubType: "store",
      };

      this.store.dispatch("setLocation", data).then((d) => {
        console.log(d);
        this.$emit("success");
      });
    },
    submitPickup() {
      let selectedPickup = this.selectedPickup as any;

      let data = {
        type: "pickup",
        pickupPoint: {
          address: selectedPickup["ADDRESS"],
          storeId: selectedPickup["ID"],
          coordinate: {
            lon: (selectedPickup["COORDINATE"] as Array<number>)[0],
            lat: (selectedPickup["COORDINATE"] as Array<number>)[1],
          },
        },
      };

      this.store.dispatch("setLocation", data).then((d) => {
        this.$emit("success");
      });
    },
    test(d: any) {
      if (d && d.length > 0) return [d[1], d[0]];

      return [0, 0];
    },
    selectPickup(e: any) {
      let target = e.get("target");

      let list = this.$refs.yMarketPickup as Array<any>;
      for (let item of list) {
        item.options.set("iconImageHref", MAP_MARKER_DEFAULT);
      }

      if (target.properties.get("item"))
        this.selectedPickup = target.properties.get("item");

      e.get("target").options.set("iconImageHref", MAP_MARKER_ACTIVE);
    },
  },
  computed: {
    addressPickup() {
      if (this.selectedPickup) return (this.selectedPickup as any).ADDRESS;
      return "";
    },
  },
  watch: {},
  mounted() {
    this.store.dispatch("locationInit").then((data) => {
      this.storesList = data.storesList;

      if (data.deliveryType) {
        this.currentDeliveryType = data.deliveryType;
      }

      if (data.pickupPoint) {
        let findSelectedPickup = this.storesList.find(
          (item: any) => item.ID == data.pickupPoint.storeId
        );
        if (findSelectedPickup) {
          this.selectedPickup = findSelectedPickup;
        }
      }

      if (data.deliveryPoint) {
        this.location = data.deliveryPoint;
        this.centerCoordinate = [
          parseFloat(data.deliveryPoint.coordinate.lat),
          parseFloat(data.deliveryPoint.coordinate.lon),
        ];
      }

      if (
        this.currentDeliveryType == "delivery" &&
        data.deliveryPoint == null
      ) {
        navigator.geolocation.getCurrentPosition(
          ({ coords }) => {
            this.changeCoordinate([coords.latitude, coords.longitude]);
          },
          ({ message }) => {
            console.log(message);
          },
          {
            enableHighAccuracy: false,
            timeout: 4000,
            maximumAge: 0,
          }
        );
      }

      this.isInit = true;
    });
  },
  setup() {
    const store = useStore();
    return {
      store,
      deliveryPoint: computed(() => store.state.deliveryPoint),
    };
  },
});
