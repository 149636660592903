import {IOffer} from "@/models/offer";

export interface IItem{
    id: number;
    available: boolean;
    name: string;
    type: ItemType
    sectionId: number;
    piece: string;
    images: Array<string>;
    offers?: Array<IOffer>;
    price: number;
    ratioPrice: number;
    discountPercent: number;
    discountRatio: number;
    ratio:number;
    measure:string;
}

export enum ItemType{
    Simple=1,
    WitOffers=3
}
