
import { defineComponent, inject } from "vue";
import Api from "@/services/api";
import { useVuelidate } from "@vuelidate/core";
import { required, email, helpers } from "@vuelidate/validators";
import TheAccount from "@/components/TheAccount.vue";
import AccountOrder from "@/components/AccountOrder.vue";
import { $vfm } from "vue-final-modal";

export default defineComponent({
  data() {
    return {
      id: 0,
      birthdate: "",
      // account: false,
      phone: "",
      email: "",
      name: "",
      // loading: false,
      firstLoading: true,
      errors: [],
    };
  },

  mounted() {
    this.api
      .userGet()
      .then((data) => {
        this.id = data.data.id;
        this.name = data.data.name;
        this.phone = data.data.phone;
        this.email = data.data.email;
        this.birthdate = data.data.birthdate;
        this.firstLoading = false;
      })
      .catch((err) => {
        let data = err.response.data;
        this.errors = data.errors;
      });
  },
  methods: {
    buttonEdit(e: any) {
      console.log(e.target);
      let loader = e.target.querySelector('.loader')
      let btn = e.target.querySelector('i')
      loader.style.display = 'block';
      btn.style.display = 'none';
      // this.loading = true;
      this.v$.$validate().then((result) => {
        if (result) {
          this.api
            .userСhanges({
              id: this.id,
              name: this.name,
              email: this.email,
              phone: this.phone,
              birthdate: this.birthdate,
            })
            .then((data) => {
              console.log(data);
              // this.loading = false;
              loader.style.display = 'none';
              btn.style.display = 'block';
              e.target.parentNode.querySelector("input").disabled = true;
              e.target.parentNode.querySelector("img").style.display = "block";
              e.target.parentNode.querySelector("button").style.display =
                "none";
            })
            .catch((err) => {
              let data = err.response.data;
              this.errors = data.errors;
            });
        } else {
          loader.style.display = 'none';
          btn.style.display = 'block';
        }
      });
    },

    authLogout() {
      this.api
        .authLogout()
        .then((data) => {
          location.reload();
        })
        .catch((err) => {
          let data = err.response.data;
          this.errors = data.errors;
        });
    },
    editingField(e: any) {
      e.target.parentNode.querySelector("input").disabled = false;
      e.target.parentNode.querySelector("input").focus();
      e.target.parentNode.querySelector("img").style.display = "none";
      e.target.parentNode.querySelector("button").style.display = "flex";
    },
    // openAccount(phone: any) {
    //   this.phone = phone;
    //   this.account = true;
    // },
    checkForm(e: any) {
      this.errors = [];
    },

    openOrderHistory() {
      // this.account = false;
      // this.$emit("openOrderHistory", true);
      $vfm.show({
        component: TheAccount,
        bind: { title: "История заказов", back: true },
        slots: {
          content: {
            component: AccountOrder,
          },
        },
      });
    },
    radioChangeCard(event: any) {
      // console.log(event.value);
    },
  },
  validations() {
    return {
      phone: {
        phone: helpers.withMessage(
          "Не корректный формат",
          helpers.regex(/\+7\s\(\d{3}\)\s\d{3}-\d{2}-\d{2}/)
        ),
      },
      email: {
        email: helpers.withMessage("Не корректный формат", email),
      },
      birthdate: {
        birthdate: helpers.withMessage(
          "Не корректный формат",
          helpers.regex(
            /^(?:0[1-9]|[12]\d|3[01])([/.-])(?:0[1-9]|1[012])\1(?:19|20)\d\d$/
          )
        ),
      },
    };
  },
  setup() {
    const api = inject<Api>("api", () => new Api(), true);
    return {
      api,
      v$: useVuelidate(),
    };
  },
});
